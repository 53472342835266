import Colors from './colors';

const Theme = {
  columnGapPixelsMob: 20,
  columnGapPixelsTab: 20,
  columnGapPixelsDesk: 20,

  sectionGapPixelsMob: 60,
  sectionGapPixelsTab: 80,
  sectionGapPixelsDesk: 80,

  headerHeightPixelsMob: 60,
  headerHeightPixelsTab: 60,
  headerHeightPixelsDesk: 80,

  logoWebColor: Colors.accent,
  logoOlogyColor: Colors.dark,

  generalDarkColor: Colors.dark,
  generalMidColor: Colors.mid,
  generalMidTextColor: Colors.midText,
  generalAccentColor: Colors.accent,
  generalSecondaryAccentColor: Colors.secondaryAccent,

  menuLinkColor: Colors.darkText,
  menuLinkColorInverted: '#fff',
  menuLineColor: Colors.accent,
  menuLineColorInverted: Colors.dark,
  menuLineHoverColor: Colors.mid,
  menuLineHoverColorInverted: Colors.mid,
  menuLinePixels: 3,

  mobMenuBackgroundColor: Colors.accent,
  mobMenuColor: '#fff',

  hamburgerTwoLines: true,
  hamburgerWidthPixels: 25,
  hamburgerHeightPixels: 17,
  hamburgerLinePixels: 2,
  hamburgerBorderRadius: 0,

  taglineColor: Colors.midText,

  formLabelColor: Colors.midText,
  formErrorTextColor: Colors.errorText,
  formInputTextColor: Colors.darkText,
  formDisabledTextColor: Colors.midText,
  formMessageColor: Colors.midText,
  formBorderColor: Colors.dark,
  formBorderErrorColor: Colors.error,
  formBorderDisabledColor: Colors.mid,
  formAccentColor: Colors.accent,
  formFieldBoxShadow: 'inset 0px 3px 0px 0px rgba(0, 0, 0, 0.08)',
  formFieldBoxShadowFocussed: `inset 0px 3px 0px 0px ${Colors.accentLight}`,

  linkColor: 'currentColor',
  linkLineColor: Colors.accentLight,
  linkFontWeight: 'bold',
  linkLinePixels: 3,
  altLinkColor: Colors.accent,
  altLinkLineColor: Colors.accentLight,
  altLinkFontWeight: 'bold',

  buttonColor: '#fff',
  buttonBackgroundColor: Colors.mid,
  buttonLineColor: Colors.dark,
  buttonActiveColor: '#fff',
  buttonActiveBackgroundColor: Colors.dark,
  buttonActiveLineColor: Colors.accent,
  buttonAccentColor: '#fff',
  buttonAccentBackgroundColor: Colors.accent,
  buttonAccentLineColor: Colors.dark,
  buttonLinePixels: 6,
  buttonHeightPixels: 60,
  buttonHeightShortPixels: 40,
  buttonShadowFilter: 'drop-shadow(2px 3px 1px rgba(0, 0, 0, 0.25))',
  buttonFocusOutline: `3px solid ${Colors.accentLight}`,

  darkBackgroundColor: Colors.dark,

  breadcrumbSeparatorTextColor: Colors.midText,

  blogTagDisabledColor: Colors.mid,
  blogTagDisabledTextColor: Colors.midText,
  blogSummaryLineColor: Colors.mid,
  blogSummaryTextColor: Colors.secondaryAccent,
  blogRelatedHeadingColor: Colors.midText,
};

export default Theme;