class scrollLock {
  constructor(element) {
    this.element = element;
    this.scrollPosition = 0;
  }

  lock() {
    this.scrollPosition = Math.round(window.scrollY);
    this.element.current.style.position = 'fixed';
    this.element.current.style.top = `${-this.scrollPosition}px`;
  }

  unlock() {
    this.element.current.style.position = '';
    this.element.current.style.top = '';

    if (this.scrollPosition !== 0) {
      window.scrollTo(0, this.scrollPosition);
      this.scrollPosition = 0;
    }
  }
}

export default scrollLock;