const Colors = {
    dark: '#474C55',
    mid: '#A4A7A9',
    accent: '#008FB4',
    accentLight: 'rgba(0,143,180,0.3)',
    secondaryAccent: '#98134c',
    secondaryAccentLight: 'rgba(152, 19, 76, 0.3)',
    error: '#cb241e',
    darkText: '#333',
    midText: '#999',
    errorText: '#ba1c1c',
};

export default Colors;