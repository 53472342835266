import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Helmet from 'react-helmet';
import { ThemeProvider } from 'styled-components';
import Header, { HeaderSpacer } from './header';
import Theme from '../utils/theme';
import scrollLock from '../utils/scroll-lock';

class PageContent extends React.Component {
  constructor(props) {
    super(props);

    this.onShowMobMenuChange = this.onShowMobMenuChange.bind(this);
    this.bodyRef = React.createRef();
    this.scrollLock = new scrollLock(this.bodyRef);
  }

  onShowMobMenuChange(isOpen) {
    if (isOpen) {
      this.scrollLock.lock();
    } else {
      this.scrollLock.unlock();
    }
  }

  render() {
    const data = this.props.data;
    const location = this.props.location;
    const children = this.props.children;

    const name = data.site.siteMetadata.name;
    const fullTitle = `${data.site.siteMetadata.title} | ${name}`;
    const url = `${data.site.siteMetadata.siteUrl}${location.pathname}`;
    return (
      <ThemeProvider theme={Theme}>
        <>
          <Helmet
            defaultTitle={fullTitle}
            titleTemplate={`%s | ${name}`}
          >

            {/* Default description */}
            <meta name="description" content={data.site.siteMetadata.description}/>

            {/* <meta name="twitter:site" content="@webologynetau" /> */}
            <meta name="og:title" content={fullTitle} />
            <meta name="og:type" content="website" />
            <meta name="og:url" content={url} />
            <meta name="og:site_name" content={name} />
            <meta name="google-site-verification" content="yd8WZJhDObI7qKWFLreKHQ5bOBl_4LedK84cry2RRkI" />
            <meta name="p:domain_verify" content="e0cb6fa088ab350047fc1b6627e6dfb7" />
            <link
              rel="canonical"
              href={url}
            />
            <html lang="en" />
          </Helmet>

          <Header
            siteName={name}
            tagline={data.site.siteMetadata.tagline}
            location={location}
            onShowMobMenuChange={this.onShowMobMenuChange}
            />
          <div  ref={this.bodyRef}>
            <HeaderSpacer/>
            {children}
          </div>
        </>
      </ThemeProvider>
    );
  }
}

const Page = ({ children, location }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          site {
            siteMetadata {
              name
              tagline
              title
              description
              siteUrl
            }
          }
        }
      `}

      render={ data => <PageContent children={children} data={data} location={location}/> }
    />
  );
};

export default Page; 