import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const getTransform = (props, closedYOffest, openAngle) => {
  const ty = props.isOpen ? props.heightPixels / 2 : closedYOffest;
  const a = props.isOpen ? `${openAngle}deg` : '0';
  return `translate3d(0,${ty}px,0) rotate(${a})`;
};

const getMargin = props => {
  const xDelta = props.targetWidthPixels - props.widthPixels;
  const yDelta = props.targetHeightPixels - props.heightPixels;

  const top = `-${Math.floor(yDelta / 2)}px`;
  const bottom = `-${Math.ceil(yDelta / 2)}px`;
  const left = `-${Math.floor(xDelta / 2)}px`;
  const right = `-${Math.ceil(xDelta / 2)}px`;

  return `${top} ${right} ${bottom} ${left}`;
};

const getPadding = props => {
  const xDelta = props.targetWidthPixels - props.widthPixels;
  const yDelta = props.targetHeightPixels - props.heightPixels;

  const top = `${Math.floor(yDelta / 2)}px`;
  const bottom = `${Math.ceil(yDelta / 2)}px`;
  const left = `${Math.floor(xDelta / 2)}px`;
  const right = `${Math.ceil(xDelta / 2)}px`;

  return `${top} ${right} ${bottom} ${left}`;
};

const Container = styled.div`
  width: ${props => props.targetWidthPixels}px;
  height: ${props => props.targetHeightPixels}px;

  margin: ${props => getMargin(props)};
  padding: ${props => getPadding(props)};

  position: relative;
`;

const Line = styled.span`
  display: block;
  height: ${props => props.linePixels}px;
  width: ${props => props.widthPixels}px;
  background: ${props => props.color};
  transition-timing-function: ${props => props.transitionTimingFunction};
  transition-duration: ${props => props.transitionDurationMS}ms;
  border-radius: ${props => props.borderRadius}px;
  transform-origin: center;
  position: absolute;
`;

const LineTop = styled(Line)`
  transform: ${props => getTransform(
    props,
    props.twoLines ? Math.ceil(0.22 * props.heightPixels) : 0,
    props.reverseAnimationDirection ? 45 : -45)};
`;

const LineMiddle = styled(Line)`
  transition-timing-function: ease-out;
  transition-duration: ${props => props.transitionDurationMS / 4}ms;
  opacity: ${props => props.twoLines || props.isOpen ? '0' : '1'};
  top: ${props => (props.heightPixels / 2) - (props.linePixels / 2)}px;
`;

const LineBottom = styled(Line)`
  transform: ${props => getTransform(
    props,
    Math.floor((props.twoLines ? 0.78 : 1) * props.heightPixels) - props.linePixels,
    props.reverseAnimationDirection ? -45 : 45)};
`;

class Hamburger extends React.Component{
  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);

    this.state = {
      isOpen: false
    };
  }

  onClick(e) {
    this.setState(
      (state, props) => ({isOpen: !state.isOpen}),
      () => this.props.onChange(this.state.isOpen)
    );
  }

  render() {
    return (
      <Container {...this.props} isOpen={this.state.isOpen} onClick={this.onClick}>
        <LineTop {...this.props} isOpen={this.state.isOpen} />
        <LineMiddle {...this.props} isOpen={this.state.isOpen} />
        <LineBottom {...this.props} isOpen={this.state.isOpen} />
      </Container>
    );
  }
}

Hamburger.defaultProps = {
  onChange: (isOpen) => {},
  twoLines: false,
  reverseAnimationDirection: false,
  widthPixels: 36,
  heightPixels: 30,
  targetWidthPixels: 36,
  targetHeightPixels: 30,
  linePixels: 2,
  color: '#000',
  borderRadius: 0,
  transitionDurationMS: 400,
  transitionTimingFunction: 'ease'
};

Hamburger.propTypes = {
  onChange: PropTypes.func,
  twoLines: PropTypes.bool,
  reverseAnimationDirection: PropTypes.bool,
  widthPixels: PropTypes.number,
  heightPixels: PropTypes.number,
  targetWidthPixels: PropTypes.number,
  targetHeightPixels: PropTypes.number,
  linePixels: PropTypes.number,
  color: PropTypes.string,
  borderRadius: PropTypes.number,
  transitionDurationMS: PropTypes.number,
  transitionTimingFunction: PropTypes.string,
};

export default Hamburger;
