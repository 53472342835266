import { css } from 'styled-components';

const tabPixels = 600;
const deskPixels = 900;
const xlDeskPixels = 1400;

const smallerThan = (pixels, ...styles) => (
    css`@media (max-width: ${(pixels - 1) / 16}em) {
        ${css(...styles)}
    }`
);

const startingFrom = (pixels, ...styles) => (
    css`@media (min-width: ${pixels / 16}em) {
        ${css(...styles)}
    }`
);

const within = (pixelsStart, pixelsEnd, ...styles) => (
    css`@media (min-width: ${pixelsStart / 16}em) and (max-width: ${(pixelsEnd - 1) / 16}em) {
        ${css(...styles)}
    }`
);

const media = {
    mobOnly: (...styles) => smallerThan(tabPixels, ...styles),
    tab: (...styles) => startingFrom(tabPixels, ...styles),
    tabDown: (...styles) => smallerThan(deskPixels, ...styles),
    tabOnly: (...styles) => within(tabPixels, deskPixels, ...styles),
    desk: (...styles) => startingFrom(deskPixels, ...styles),
    xlDesk: (...styles) => startingFrom(xlDeskPixels, ...styles),
};

export default media;