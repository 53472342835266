import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import styled, { withTheme } from 'styled-components';

const menuColor = props => props.invertcolors
  ? props.theme.menuLinkColorInverted
  : props.theme.menuLinkColor;
const menuLineColor = props => props.invertcolors
  ? props.theme.menuLineColorInverted
  : props.theme.menuLineColor;
const menuLineHoverColor = props => props.invertcolors
  ? props.theme.menuLineHoverColorInverted
  : props.theme.menuLineHoverColor;

const Nav = styled.nav`
  display: flex;
  flex-direction: ${props => props.vertical ? 'column' : 'row'};
  align-items: center;
  height: 100%;
`;

const MenuColumn = styled.div`
  flex-grow: 0;
  flex-shrink: 1;
  width: ${props => props.vertical ? 'auto' : '25%'};
  height: ${props => props.vertical ? 'auto' : '100%'};

  &:not(:last-child) {
    padding-right: ${props => props.vertical ? '0' : '5px'};
  }
`;

const MenuLink = styled(Link)`
  text-decoration: none;
  font-weight: bold;
  color: ${props => menuColor(props)};
  outline: none;

  display: ${props => props.fullwidthlink ? 'flex' : 'inline-flex'};
  flex-direction: column;
  justify-content: stretch;
  align-items: flex-start;
  height: 100%;
  width: auto;
  white-space: nowrap;

  .label-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    border-top: ${props => props.theme.menuLinePixels}px solid transparent;
    border-bottom: ${props => props.theme.menuLinePixels}px solid transparent;
  }

  &:hover {
    .label-container {
      ${props => props.lineabove
        ? `border-top: ${props.theme.menuLinePixels}px solid ${menuLineHoverColor(props)};`
        : `border-bottom: ${props.theme.menuLinePixels}px solid ${menuLineHoverColor(props)};`
      }
    }
  }

  &:focus {
    outline: ${props => props.theme.buttonFocusOutline};
    outline-offset: 2px;
  }
`;

const MenuLinkActive = styled(MenuLink)`
  .label-container {
    ${props => props.lineabove
      ? `border-top: ${props.theme.menuLinePixels}px solid ${menuLineColor(props)};`
      : `border-bottom: ${props.theme.menuLinePixels}px solid ${menuLineColor(props)};`
    }
  }
`;

const MenuItem = (props) => {
  const getBaseLocation = s => s.trim().replace(/(.+)\/.*$/, '$1');
  const MyLink = getBaseLocation(props.location.pathname) === getBaseLocation(props.to)
    ? MenuLinkActive
    : MenuLink
  return (
    <MenuColumn vertical={props.vertical ? 1 : 0}>
      <MyLink
        to={props.to}
        lineabove={props.lineabove ? 1 : 0}
        invertcolors={props.invertcolors ? 1 : 0}
        fullwidthlink={props.fullwidthlink ? 1 : 0}
        theme={props.theme}>
        <span className="label-container">
          <span className={props.isSmall ? 'small' : ''}>{props.children}</span>
        </span>
      </MyLink>
    </MenuColumn>
  )
};

const Menu = (props) => (
  <Nav {...props} className={props.className}>
    <MenuItem {...props} to="/">home</MenuItem>
    {/* <MenuItem {...props} to="/process">process</MenuItem> */}
    <MenuItem {...props} to="/news">news</MenuItem>
    <MenuItem {...props} to="/contact">contact</MenuItem>
  </Nav>
);

Menu.defaultProps = {
  lineabove: false,
  invertcolors: false,
  fullwidthlink: false,
  isSmall: true,
};

Menu.propTypes = {
  location: PropTypes.object.isRequired,
  lineabove: PropTypes.bool,
  invertcolors: PropTypes.bool,
  fullwidthlink: PropTypes.bool,
  isSmall: PropTypes.bool,
};

export default withTheme(Menu);
