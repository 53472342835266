import styled from 'styled-components';
import media from '../utils/media';

const multiplier = props => props.gapMultiplier || 1;

const Section = styled.section`
    margin-bottom: ${props => props.theme.sectionGapPixelsMob * multiplier(props)}px;

    ${media.tab`
        margin-bottom: ${props => props.theme.sectionGapPixelsTab * multiplier(props)}px;
    `}

    ${media.desk`
        margin-bottom: ${props => props.theme.sectionGapPixelsDesk * multiplier(props)}px;
    `}
`;

export default Section;
