import styled from 'styled-components';
import media from './media';

const LayoutRow = styled.div`
    width: 100%;
    max-width: 1360px;
    padding-left: ${props => props.theme.columnGapPixelsMob}px;
    padding-right: ${props => props.theme.columnGapPixelsMob}px;
    position: relative;

    ::after {
        content: '';
        display: block;
        clear: both;
    }

    ${media.tab`
        padding-left: ${props => props.theme.columnGapPixelsTab}px;
        padding-right: ${props => props.theme.columnGapPixelsTab}px;
    `}

    ${media.desk`
        padding-left: ${props => props.theme.columnGapPixelsDesk}px;
        padding-right: ${props => props.theme.columnGapPixelsDesk}px;
    `}

    @supports(display: grid) {
        --notch-left: 0px;
        --notch-right: 0px;
        @supports(column-gap: env(safe-area-inset-left)) {
            --notch-left: env(safe-area-inset-left);
            --notch-right: env(safe-area-inset-right);
        }

        max-width: none;
        padding-left: 0;
        padding-right: 0;
        display: grid;
        column-gap: ${props => props.theme.columnGapPixelsMob}px;
        
        grid-template-columns:
            [full-start] calc(var(--notch-left) - ${props => props.theme.columnGapPixelsMob}px)
            [side-start main-start] minmax(0, 100%)
            [main-end side-end] calc(var(--notch-right) - ${props => props.theme.columnGapPixelsMob}px) [full-end];

        ${media.tab`
            column-gap: ${props => props.theme.columnGapPixelsTab}px;
            grid-template-columns:
                [full-start] calc(var(--notch-left) - ${props => props.theme.columnGapPixelsTab}px)
                [side-start] minmax(0, 300px)
                [side-end main-start] minmax(0, 300px)
                minmax(0, 300px)
                minmax(0, 300px)
                [main-end] calc(var(--notch-right) - ${props => props.theme.columnGapPixelsTab}px) [full-end];
        `}

        ${media.desk`
            column-gap: ${props => props.theme.columnGapPixelsDesk}px;
            grid-template-columns:
                [full-start] minmax(${props => props.theme.columnGapPixelsDesk}px, 1fr)
                [side-start] minmax(0, 300px)
                [side-end main-start] minmax(0, 300px)
                minmax(0, 300px)
                minmax(0, 300px)
                [main-end] minmax(${props => props.theme.columnGapPixelsDesk}px, 1fr) [full-end];
        `}
        }
    }
`;

const FullWidthBackground = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    height: 100%;
    z-index: -100;

    @supports (display: grid) {
        grid-column: full;
        grid-row: 1;
    }
`;

const LeftSideBackground = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    height: 100%;
    z-index: -100;

    @supports (display: grid) {
        grid-row: 1;
        grid-column: full;

        ${media.desk`
            grid-column: full-start / main-end;
        `}
    }
`;

const RightSideBackground = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    height: 100%;
    z-index: -100;

    @supports (display: grid) {
        grid-row: 1;
        grid-column: full;

        ${media.desk`
            grid-column: side-start / full-end;
        `}
    }
`;

const SideColumn = styled.div`
    ${media.tab`
        float: left;
        width: 25%;
        padding-right: ${props => props.theme.columnGapPixelsTab}px;
    `}

    ${media.desk`
        padding-right: ${props => props.theme.columnGapPixelsDesk}px;
    `}

    @supports(display: grid) {
        float: none;
        width: auto;
        padding-right: 0;
        grid-column: side;
    }
`;

const SideLeftBleedColumn = styled(SideColumn)`
    @supports(display: grid) {
        grid-column: full-start / side-end;
    }
`;

const MainColumn = styled.div`
    ${media.tab`
        float: right;
        width: 75%;
    `}

    @supports(display: grid) {
        float: none;
        width: auto;
        grid-column: main;
    }
`;

const MainRightBleedColumn = styled(MainColumn)`
    @supports(display: grid) {
        grid-column: main-start / full-end;
    }
`;

export {
    LayoutRow,
    FullWidthBackground,
    LeftSideBackground,
    RightSideBackground,
    SideColumn,
    MainColumn,
    SideLeftBleedColumn,
    MainRightBleedColumn
};
